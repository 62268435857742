import { extendTheme } from "@chakra-ui/react"

import { breakpoints } from "./breakpoints"
import { styles } from "./styles"
import { colors } from "./colors"
import { fonts } from "./fonts"
import { sizes } from "./sizes"
import { space } from "./spacing"
import { lineHeights } from "./typography"
import { Button, Drawer, Heading, Input, Select, Text, Textarea, Radio } from "./components"
import { Tabs } from "./components/Tabs"
import { Accordion } from "./components/Accordion"

const overrides = {
  breakpoints,
  styles,
  colors,
  sizes,
  fonts,
  lineHeights,
  space,
  components: {
    Button,
    Drawer,
    Heading,
    Input,
    Select,
    Text,
    Textarea,
    Radio,
    Tabs,
    Accordion,
  },
}

export default extendTheme(overrides)
