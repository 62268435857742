import { useMemo } from "react"
import config from "@root/config.js"

export const useProductComputed = (product: any) => {
  const findMetafield = (namespace: string, key: string) =>
    product?.metafields?.find((m: any) => m?.namespace === namespace && m?.key === key)?.value

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const productInfo = useMemo(() => findMetafield("sanity", "product_info"), [product])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const brandbankId = useMemo(() => findMetafield("sanity", "brandbank_id"), [product])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const ingredients = useMemo(() => findMetafield("sanity", "ingredients"), [product])

  const swatch = useMemo(() => {
    const name = product?.tags?.find((tag: string) => tag?.startsWith("swatch:"))?.split(":")?.[1]
    return name ? `https://allkinds-swatches.s3.ap-southeast-2.amazonaws.com/${name}` : null
  }, [product])

  const color = useMemo(() => {
    const color = product?.tags
      ?.find((tag: string) => tag?.startsWith("colour:"))
      ?.split(":")?.[1]
      ?.replaceAll(" ", "")
    return color ? color?.charAt(0)?.toLowerCase() + color?.slice(1) : null
  }, [product])

  const isGiftCard = useMemo(() => product?.productType === config?.store?.giftCardType, [product?.productType])

  // productSize is a string in grams e.g 250g or ml e.g 250ml
  const productSize: string = useMemo(() => product?.tags?.find((tag: string) => tag.startsWith("size:"))?.split(":")?.[1], [product])

  const productSizeText = useMemo(() => {
    const gramsToOz = 0.035274
    const mlToOz = 0.033814

    if (productSize?.toLowerCase().includes("ml")) return `${productSize} ${(parseInt(productSize) * mlToOz).toFixed(1)} fl oz`
    if (productSize?.toLowerCase()?.includes("g")) return `${productSize} ${(parseInt(productSize) * gramsToOz).toFixed(1)} oz`
    return ""
  }, [productSize])

  return { productInfo, brandbankId, ingredients, swatch, color, isGiftCard, productSizeText }
}
